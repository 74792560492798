import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section, { ColumnsSection } from '../components/Section';
import LinkButton from '../components/LinkButton';
import { MainTitle, SectionTitle }  from '../components/Titles';
import Activity from '../components/Activity';
import MainImageSection from '../components/MainImageSection';

import { colors } from '../shared/styles';

const MessageSection = styled(Section).attrs(() => ({
  wrapperStyle : {
    height : '100%'
  }
}))`
  height : 100%;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : center;
  padding : 10rem;
`;

const Message = styled.p`
  font-size : 3rem;
  font-family : TellMeMore;
  line-height : 6rem;
  letter-spacing : 0.5rem;
  text-align : center;
`

const Page = () => (
  <Layout>
    <SEO title="Envoyé" />
    <MessageSection>
      <Message>
        Votre message a été envoyé!
      </Message>
      <Message>
        Nous allons vous répondre dans les plus brefs délais.
      </Message>
    </MessageSection>
  </Layout>
)

export default Page
